"use client";

import { Fragment, useEffect, useState } from "react";
import { initializePaddle } from "@paddle/paddle-js";
import axios from "axios";
import Loader from "@litonarefin/components/Loader";
import { useRouter } from "next/navigation";
import { cn, generateRandomPassword } from "@litonarefin/utils/index";
import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";
import { getBorderCSS } from "@litonarefin/utils/getBorderCSS";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";

const PaddleCheckoutButton = ({ attributes }) => {
  const [paddle, setPaddle] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const {
    btnText = "Buy Now",
    paddleProductId,
    bundle = false,
    bundleProductsIds = [],
    freemiusProducts = [],
    couponCode = "",
    settings = {},
    lists = [],
    tags = [],
    className = "",
    style = {},
    btnColor,
    btnBgColor,
    border,
    borderRadius,
    padding,
    width,
  } = attributes;

  const router = useRouter();

  /**
   * Handle Checkout
   */
  const handleCheckout = async () => {
    setIsLoading(true);

    paddle?.Checkout.open({
      items: [
        {
          priceId: paddleProductId,
          quantity: 1,
        },
      ],
      discountCode: couponCode || null,
      settings,
      customData: {
        lists,
        tags,
        bundle,
        bundle_products_ids: bundleProductsIds,
        freemius_products: freemiusProducts,
      },
    });
  };

  /**
   * Add Full Screen Loader in append body
   * @param {boolean} condition
   */
  const getFullScreenLoader = (condition) => {
    const body = document.body;
    if (condition) {
      const loaderWrapper = document.createElement("div");
      loaderWrapper.classList.add("jlt_pricing_full_overlay_loader");
      body.appendChild(loaderWrapper);
    } else {
      const loaderWrapper = document.querySelector(".jlt_pricing_full_overlay_loader");
      body.removeChild(loaderWrapper);
    }
  };

  /**
   * Handle Payment Action
   * @param {*} event
   * @returns
   */
  const actionHandle = async (event) => {
    setIsLoading(false);
    if (!event.name) {
      return;
    }

    switch (event.name) {
      case "checkout.loaded":
        break;
      case "checkout.closed":
        setIsLoading(false);
        break;
      case "checkout.customer.created":
        getFullScreenLoader(true);

        const order = await createOrder(event.data);

        if (order?.user_hash) {
          sessionStorage.setItem("jlt_hash", order?.user_hash);
        }

        if (order?.order_id) {
          sessionStorage.setItem("jlt_oid", order?.order_id);
          getFullScreenLoader(false);
        }

        // const existingUserId = await checkExistingUser(event.data.customer.email);

        // if (existingUserId?.result?.userId) {
        //     sessionStorage.setItem("jlt_uf", existingUserId?.result?.userId);

        //     const order = await createOrder(existingUserId?.result?.userId, event.data);
        //     if (order?.order_id) {
        //         sessionStorage.setItem("jlt_oid", order?.order_id);
        //     }
        // } else {
        //     const user = await createUser(event.data.customer.email);
        //     sessionStorage.setItem("jlt_uf", user?.result?.user_id);

        //     const order = await createOrder(user?.result?.user_id, event.data);
        //     if (order?.order_id) {
        //         sessionStorage.setItem("jlt_oid", order?.order_id);
        //     }
        // }
        break;
      case "checkout.payment.selected":
        break;
      case "checkout.discount.applied":
        // const userId = sessionStorage.getItem("jlt_uf");
        const updateOrder = await updateOrderStatus("pending", "", event.data.discount.code);
        break;
      case "checkout.discount.removed":
        // const removeUpdateOrder = await updateOrderStatus("pending", "", "");
        break;
      case "checkout.items.updated":
        // const userId = sessionStorage.getItem("jlt_uf");

        break;
      case "checkout.payment.initiated":
        break;
      case "checkout.completed":
        await updateOrderStatus(
          "completed",
          event?.data?.transaction_id,
          "",
          event?.data?.customer?.email,
          event?.data?.customer?.address?.id,
          event?.data
        );
        break;
      default:
        setIsLoading(false);
        break;
    }
  };

  /**
   * Create Order
   */
  const createOrder = async (data) => {
    const billing = {
      first_name: "",
      last_name: "",
      email: data?.customer?.email || "",
      company: "",
      country: "",
      city: "",
      postCode: "",
      password: "",
    };

    let lineItems = [
      {
        price_id: data?.items?.[0]?.price_id,
      },
    ];

    if (data?.custom_data?.bundle && data?.custom_data?.bundle_products_ids?.length) {
      lineItems = data?.custom_data?.bundle_products_ids;
    }

    const orderData = {
      order_status: "pending",
      total: data.totals.total,
      billing: billing,
      shipping: billing,
      bundle: bundle, // TRUE | FALSE
      line_items: lineItems,
      freemius_product_data: data?.custom_data?.freemius_products,
      coupon_code: data?.discount?.code || "",
      transaction_id: data.transaction_id,
      lists: data?.custom_data?.lists,
      tags: data?.custom_data?.tags,
      payment_method: "sparkle_paddle_checkout_overlay",
      payment_method_title: "Sparkle Paddle Overlay",
      site_source: process.env.NEXT_PUBLIC_SOURCE_SITE,
    };

    const { data: orderCreateData } = await axios.post(
      `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/orders/create`,
      orderData
    );

    return orderCreateData;
  };

  /**
   * Update Order Status
   * @param {'pending' | 'completed'} status
   * @param {object} data
   */
  const updateOrderStatus = async (
    status,
    trans_id = "",
    coupon_code = "",
    email = "",
    address_id = "",
    eventData = {}
  ) => {
    const orderId = sessionStorage.getItem("jlt_oid");
    const hash = sessionStorage.getItem("jlt_hash");

    const { data } = await axios.put(
      `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/orders/paddle-update`,
      {
        gutenberg: true,
        body: {
          order_id: orderId,
          trans_id: trans_id,
          coupon_code: coupon_code,
          status: status,
          email: email,
          paddle_address_id: address_id,
          pending_tags: eventData?.custom_data?.tags?.[0]
            ? [eventData?.custom_data?.tags?.[0]]
            : [],
        },
      }
    );

    if (data?.data?.data?.status === "completed") {
      window.Paddle.Checkout.close();
      sessionStorage.removeItem("jlt_oid");
      // Freemius Product create User & Assign license
      if (eventData?.custom_data?.bundle) {
        eventData?.custom_data?.freemius_products.forEach(async (product) => {
          const password = generateRandomPassword();

          const res = await fetch(`/api/freemius-bundle-purchase`, {
            method: "POST",
            body: JSON.stringify({
              email,
              password,
              product,
            }),
          });

          if (!res.ok) return;

          const result = await res.json();
        });
      }

      // Redirect order received page
      // const url = `${process.env.NEXT_PUBLIC_PIXARLABS_SITE_URL}/order-received?hash=${hash}&oid=${orderId}`;
      // window.open(url, "_blank");
      // router.push(`/thank-you?hash=${hash}&oid=${orderId}`);
    }
  };

  useEffect(() => {
    // Initialization Paddle
    initializePaddle({
      environment: process.env.NEXT_PUBLIC_PADDLE_MODE || "production",
      token: process.env.NEXT_PUBLIC_PADDLE_VENDOR_AUTH_CODE,
      eventCallback: actionHandle,
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  useEffect(() => {
    let timeout;
    if (isLoading) {
      timeout = setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [isLoading]);

  return (
    <Fragment>
      <button
        {...(attributes?.anchor && { id: attributes?.anchor })}
        className={cn(
          "jlt-checkout-btn jlt-shadow-[0px_0px_20px_rgba(173,173,188,0.15)] jlt-rounded-[25px] jlt-py-3 jlt-px-6 jlt-font-bold jlt-text-sm jlt-leading-[18px] jlt-text-center jlt-text-white jlt-border-none jlt-cursor-pointer jlt-flex jlt-items-center jlt-gap-2",
          className
        )}
        onClick={handleCheckout}
        disabled={!!isLoading ? true : false}
        style={style}
      >
        {!!isLoading ? (
          <Loader
            type="button-loader"
            styles={{
              width: "24px",
              height: "24px",
            }}
          />
        ) : null}
        <span>{btnText}</span>
      </button>

      <style jsx>{`
        .jlt-checkout-btn {
          ${getMinifyCSS(`
            ${btnBgColor ? `background: ${btnBgColor};` : ""}
            ${btnColor ? `color: ${btnColor};` : ""}
            ${borderRadius ? `border-radius: ${borderRadius}px;` : ""}
            ${width ? `width: ${width};` : ""}
            ${getBorderCSS(border) ? getBorderCSS(border) : ""}
            ${getDimensionCSS(padding, "padding") ? getDimensionCSS(padding, "padding") : ""}
          `)}
        }
      `}</style>
    </Fragment>
  );
};

export default PaddleCheckoutButton;
